import './Footer.css'

const Footer = () => (
  <footer className='footer'>
    <a href='https://aymecho.dev' className='link footer__link'>
      © 2023 aymecho.dev. All rights reserved.
    </a>
  </footer>
)

export default Footer
