import React, { useEffect, useState } from 'react'
import GitHubIcon from '@material-ui/icons/GitHub'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { about } from '../../portfolio'
import './About.css'

const About = () => {
  const { name, role, description, social } = about
  const [typedName, setTypedName] = useState('')
  const [showCursor, setShowCursor] = useState(true)

  useEffect(() => {
    let index = 0
    const typingSpeed = 250
    const pauseDuration = 3000
    const erasingSpeed = 150

    function eraseLetter() {
      if (index > 0) {
        setTypedName((prev) => prev.slice(0, -1))
        index--
        setTimeout(eraseLetter, erasingSpeed)
      } else {
        typeLetter()
      }
    }

    function typeLetter() {
      if (index < name.length) {
        setTypedName((prev) => prev + name[index])
        index++
        setTimeout(typeLetter, typingSpeed)
      } else {
        setTimeout(eraseLetter, pauseDuration)
      }
    }

    typeLetter()
  }, [name])

  return (
    <div className='about center'>
      {name && (
        <h1>
          Hey, I'm <span className='about__name'>{typedName}</span>
          {showCursor && <span className='cursor'>|</span>}
        </h1>
      )}

      {role && <h2 className='about__role'>{role}.</h2>}
      <p className='about__desc'>{description && description}</p>

      <div className='about__contact center'>
        {social && (
          <>
            {social.github && (
              <a
                href={social.github}
                aria-label='github'
                className='link link--icon'
              >
                <GitHubIcon />
              </a>
            )}

            {social.linkedin && (
              <a
                href={social.linkedin}
                aria-label='linkedin'
                className='link link--icon'
              >
                <LinkedInIcon />
              </a>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default About
