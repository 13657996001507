const header = {
  homepage: 'https://aymecho.dev',
  title: 'aaymecho.dev',
}

const about = {
  name: 'Addisalem',
  role: 'Electrical Engineering Student',
  description:
    "I'm an undergraduate student at Georgia Institute of Technology, majoring in Electrical Engineering with a focus on signals & information processing and robotics. I'm actively seeking a summer 2024 internship in controls and automation.",

  social: {
    linkedin: 'https://www.linkedin.com/in/addisalem-aymecho-514342162/',
    github: 'https://github.com/aaymecho',
  },
}

const projects = [
  {
    name: 'Balancing an Inverted Pendulum',
    description:
      'Balanced an inverted pendulum using a C2000 TI microcontroller and a Quanser Motion System. Visualization were available in MATLAB.',
    stack: ['C-Programming', 'MATLAB', 'Control Systems'],
    livePreview: 'https://www.youtube.com/embed/QD2b3xAxpWc',
  },
  {
    name: 'Tone Generation Peripheral',
    description:
      'Created a peripherial that plays specific frequency sound (less than 1% error) using VHDL and assembly from an SCOMP architecture.',
    stack: ['Assembly', 'VHDL'],
    sourceCode: 'https://github.com/aaymecho/ECE-2031-Project',
  },
  {
    name: 'TCP Chat',
    description:
      'Implemented a TCP server/client application that can be used to recieve and send messages across multiple clients. ',
    stack: ['C++ Programming'],
    sourceCode: 'https://github.com/aaymecho/tcp-chat',
    livePreview: 'https://www.youtube.com/embed/aoH7G_xoBS8',
  },
]

const skills = [
  'Control Systems',
  'Circuit Analysis',
  'Signal Processing',
  'Measurement & Instrumentation',
  'PCB Design',
  'Raspberry Pi',
  'Arduino',
  'SPICE',
  'Python',
  'Javascript',
  'C/C++',
  'VHDL',
  'AutoCAD',
  'Revit',
  'Eagle/Fusion 360',
  'MATLAB',
]

const contact = {
  email: 'aaymecho3@gatech.edu',
}

export { header, about, projects, skills, contact }
